import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'; // Import icons for hamburger and close
import { FaChevronDown } from 'react-icons/fa';

import AOS from 'aos'; // Import AOS

import 'aos/dist/aos.css'; // Import AOS styles

const Navbar = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
    });
  }, []);

  const [navOpen, setNavOpen] = useState(false); // State to manage menu open/close
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen); // Toggle the menu
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className='  w-full flex-grow px-12 py-4 bg-[var(--bg1)] text-[var(--text1)] flex flex-row h-20 items-center border-[var(--border-color)] text-sizes'>
      <div className='flex'>
        <Link to='/'>
          <h1 className='text-sizes2 uppercase text-base md:text-lg font-bold'>RayupRealESTATE</h1>
        </Link>
      </div>

      {/* Desktop Menu */}
      <div className='hidden md:flex flex-grow justify-center'>
        <ul className='flex space-x-4'>
          <li className='px-3 py-4 cursor-pointer'>
            <Link to="/">HOME</Link>
          </li>
          <li className='px-3 py-4 cursor-pointer'>
            <Link to="/about">ABOUT US</Link>
          </li>
          <li className='px-3 py-4 cursor-pointer'>
            <Link to="/properties">PROPERTIES</Link>
          </li>


          <div className="relative inline-block">
            {/* Dropdown button */}
            <button
              onClick={toggleDropdown}
              className="px-3 py-4 uppercase flex items-center justify-between"
            >
              REAL ESTATE INSIGHTS
              <FaChevronDown className={`ml-2 transition-transform ${isOpen ? 'rotate-180' : 'rotate-0'}`} />
            </button>

            {/* Dropdown menu */}
            {isOpen && (
              <ul className="absolute left-0 mt-4 mb-4 w-full bg-[var(--bg2)] shadow-lg z-50 gap-y-2">
                <li className="px-4 py-4 text-[var(--text2)] hover:bg-[var(--gold)]">
                  <Link to="/blog">Blog</Link>
                </li>
                <li className="px-4 py-4 text-[var(--text2)] hover:bg-[var(--gold)]">
                  <Link to="/mortgages">Mortgage Calculator</Link>
                </li>
                <li className="px-4 py-4 text-[var(--text2)] hover:bg-[var(--gold)]">
                  <Link to="/buyer">Buyer's Guide</Link>
                </li>
                <li className="px-4 py-4 text-[var(--text2)] hover:bg-[var(--gold)]">
                  <Link to="/seller">Seller's Guide</Link>
                </li>
              </ul>
            )}
          </div>
          <li className='px-3 py-4 cursor-pointer'>
            <Link to="/contact">CONTACT US</Link>
          </li>
        </ul>
      </div>

      {/* Contact Us Button for Desktop */}
      <div className='hidden md:block border-[2px] md:border-[1.5px] lg:border-[1px] py-2 px-3 md:py-2 md:px-3 lg:py-3 lg:px-3 mr-4 bt1'>
        <Link to="/admin">Admin</Link>
      </div>

      {/* Hamburger Icon for Mobile */}
      <div className='md:hidden flex-grow flex justify-end'>
        <button onClick={toggleNav} className='text-2xl z-50'>
          {navOpen ? (
            <AiOutlineClose className='text-[var(--primary-text)] text-3xl' />
          ) : (
            <AiOutlineMenu className='text-[var(--primary-text)] text-3xl' />
          )}
        </button>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed top-0 left-0 w-full bg-[var(--bg1)] text-[var(--text1)] transform ${navOpen ? 'translate-y-0' : '-translate-y-full'
          } transition-transform duration-500 ease-in-out md:hidden z-40`}>
        <ul className='flex flex-col items-center space-y-4 p-8'>
          <li className='px-3 py-2 text-base'>
            <Link to="/" onClick={toggleNav}>HOME</Link>
          </li>
          <li className='px-3 py-2 text-base'>
            <Link to="/about" onClick={toggleNav}>ABOUT US</Link>
          </li>
          <li className='px-3 py-2 text-base'>
            <Link to="/properties" onClick={toggleNav}>PROPERTIES</Link>
          </li>

          <div className="relative inline-block text-base">
            {/* Dropdown button */}
            <button
              onClick={toggleDropdown}
              className="px-3 py-4 uppercase flex items-center justify-between"
            >
              REAL ESTATE INSIGHTS
              <FaChevronDown className={`ml-2 transition-transform ${isOpen ? 'rotate-180' : 'rotate-0'}`} />
            </button>

            {/* Dropdown menu */}
            {isOpen && (
              <ul className="absolute left-0 mt-4 mb-4 w-full bg-[var(--bg2)] shadow-lg z-50 gap-y-2">
                <li className="px-4 py-4 text-[var(--text2)] hover:bg-[var(--gold)]">
                  <Link to="/blog">Blog</Link>
                </li>
                <li className="px-4 py-4 text-[var(--text2)] hover:bg-[var(--gold)]">
                  <Link to="/mortgages">Mortgage Calculator</Link>
                </li>
                <li className="px-4 py-4 text-[var(--text2)] hover:bg-[var(--gold)]">
                  <Link to="/buyer">Buyer's Guide</Link>
                </li>
                <li className="px-4 py-4 text-[var(--text2)] hover:bg-[var(--gold)]">
                  <Link to="/seller">Seller's Guide</Link>
                </li>
              </ul>
            )}
          </div>

          <li className='px-3 py-2 text-base'>
            <Link to="/properties" onClick={toggleNav}>CONTACT US</Link>
          </li>

          <li className='px-3 py-2 mt-4 bt1 text-base'>
            <Link to="/admin" onClick={toggleNav}>ADMIN</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
