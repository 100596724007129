import React from 'react';

const Stats = () => {
  return (
    <div className="bg-[var(--bg1)] py-12 px-12 md:px-12 lg:px-12 xl:px-12">
      {/* Left side (Text section) */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h1 className="text-sizes3 text-[var(--text1)] font-semibold mb-12">
            HASSLE FREE EXPERIENCE
          </h1>
          <p className="text-sizes2 text-[var(--text1)] mb-12">
            A keen eye on the market, a passion to excel, and a wide network of industry professionals. Jas Oberoi Group is the best real estate team in Surrey and we work to deliver the best experience with Real Estate.
          </p>
          <p className="text-sizes text-[var(--text1)] mb-12">
            Doing more than just finding real estate and developing an investment strategy. By working with Jas Oberoi Group, we provide for every real estate need under one roof. We want to emphasize that we are not sales associates — Jas Oberoi Group is a home made up of only advisors and consultants dedicated to imparting transparent and honest advice. Our values build our character, our stories strengthen our reputation.
          </p>
          <p className="text-[var(--gold)] font-semibold text-sizes3  mt-4 text-center font1
          ">
           Sarthak Sharma
          </p>
        </div>

        {/* Right side (Stats section) */}
        <div className="border-4 border-[var(--gold)] py-12 px-8 flex text-center justify-center">
          <div className="grid grid-cols-2 gap-6">
            {/* Stats */}
            <div>
              <p className="text-[var(--text1)] font-bold text-3xl lg:text-6xl xl:text-7xl">800M+</p>
              <p className="text-[var(--text1)] text-lg lg:text-xl">in Career Sales</p>
            </div>
            <div>
              <p className="text-[var(--text1)] font-bold text-3xl lg:text-6xl xl:text-7xl">35+</p>
              <p className="text-[var(--text1)] text-lg lg:text-xl">Real Estate Awards</p>
            </div>
            <div>
              <p className="text-[var(--text1)] font-bold text-3xl lg:text-6xl xl:text-7xl">8+</p>
              <p className="text-[var(--text1)] text-lg lg:text-xl">Years of Experience</p>
            </div>
            <div>
              <p className="text-[var(--text1)] font-bold text-3xl lg:text-6xl xl:text-7xl">1K+</p>
              <p className="text-[var(--text1)] text-lg lg:text-xl">Total Sales Volume</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
