import React, { useState,useEffect } from "react";
import { FaMapMarkerAlt, FaHome, FaDollarSign, FaRuler, FaCalendarAlt } from "react-icons/fa";
import { FiSearch, FiChevronDown } from "react-icons/fi";
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles

const Top = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
    });
  }, []); 
  const [dropdown, setDropdown] = useState(null);

  const handleDropdownToggle = (index) => {
    setDropdown(dropdown === index ? null : index);
  };

  return (
    <div>
      {/* Header Section */}
      <div className="bg-[var(--primary-bg)] text-[var(--primary-text)] py-12 px-12 sm:py-8 sm:px-12 md:py-12 md:px-12 lg:py-12 lg:px-12 xl:py-12 2xl:py-12 ">
        <h1 className="text-sizes2 text-[var(--border-color)] mb-4" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500">Find Your Dream Property</h1>
        <p className="text-sizes  mr-[2rem] border-[var(--border-color)]" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
          Welcome to RayUpRealEstate, where your dream property awaits in every corner of our beautiful world. Explore our curated selection of properties, each offering a unique story and a chance to redefine your life. With categories to suit every dreamer, your journey.
        </p>
      </div>

      {/* Search and Filter Section */}
      <div className="text-sizes flex w-full flex-col items-center gap-y-2.5 py-10 sm:px-20  font-medium leading-normal bg-[var(--primary-bg)] sm:py-8  md:py-12 md:px-12  lg:py-12 lg:px-12 xl:py-12 2xl:py-12">
        {/* Search Section */}
        <div className="flex flex-wrap items-center justify-center gap-x-12 gap-y-4  border border-solid border-[var(--border-color)] bg-[var(--primary-bg)] py-4 px-3 shadow-lg w-full">
          {/* Search Input */}
          <input
            type="text"
            placeholder="Search For A Property"
            className=" text-sizes w-full lg:w-[1021px] text-center lg:text-left  leading-normal text-[dimgray] bg-transparent border-none focus:outline-none"
          />
          <div className="flex items-center justify-center gap-x-2 btn1">
            <FiSearch className="h-6 w-6 text-white" />
            <div className=" text-white">Find Property</div>
          </div>
        </div>

        {/* Options Section */}
        <div className="flex flex-wrap justify-center gap-x-5 gap-y-5 w-full rounded-t-xl bg-[var(--secondary-bg)] p-4 text-[var(--primary-text)]">
          {/* Dropdown: Location */}
          <div
            className="relative flex items-center justify-between gap-x-4  border border-solid border-[var(--border-color)] bg-[var(--primary-bg)] p-4 w-full max-w-xs cursor-pointer"
            onClick={() => handleDropdownToggle(0)}
          >
            <div className="flex items-center">
              <FaMapMarkerAlt className="h-6 w-6" />
              <div className="ml-3">Location</div>
            </div>
            <FiChevronDown className="h-6 w-6" />
            {dropdown === 0 && (
              <div className="absolute top-full left-0 w-full mt-1 bg-[var(--secondary-bg)] rounded-md shadow-lg text-[var(--primary-text)] z-50">
                <div className="py-2 px-4 hover:bg-[var(--button-color)]">Jammu and Kashmir</div>
                <div className="py-2 px-4 h hover:bg-[var(--button-color)]">Punjab</div>
              </div>
            )}
          </div>

          {/* Dropdown: Property Type */}
          <div
            className="relative flex items-center justify-between gap-x-4  border border-solid border-[var(--border-color)] bg-[var(--primary-bg)] p-4 w-full max-w-xs cursor-pointer"
            onClick={() => handleDropdownToggle(1)}
          >
            <div className="flex items-center">
              <FaHome className="h-6 w-6" />
              <div className="ml-3">Property Type</div>
            </div>
            <FiChevronDown className="h-6 w-6" />
            {dropdown === 1 && (
              <div className="absolute top-full left-0 w-full mt-1 bg-[var(--secondary-bg)] rounded-md shadow-lg text-[var(--primary-text)] z-50">
                <div className="py-2 px-4 hover:bg-[var(--button-color)]">Apartment</div>
                <div className="py-2 px-4 hover:bg-[var(--button-color)]">Villa</div>
                <div className="py-2 px-4 hover:bg-[var(--button-color)]">Commercial</div>
              </div>
            )}
          </div>

          {/* Dropdown: Pricing Range */}
          <div
            className="relative flex items-center justify-between gap-x-4  border border-solid border-[var(--border-color)] bg-[var(--primary-bg)] p-4 w-full max-w-xs cursor-pointer"
            onClick={() => handleDropdownToggle(2)}
          >
            <div className="flex items-center">
              <FaDollarSign className="h-6 w-6" />
              <div className="ml-3">Pricing Range</div>
            </div>
            <FiChevronDown className="h-6 w-6" />
            {dropdown === 2 && (
              <div className="absolute top-full left-0 w-full mt-1 bg-[var(--secondary-bg)] rounded-md shadow-lg text-[var(--primary-text)] z-50">
                <div className="py-2 px-4 hover:bg-[var(--button-color)]">$100k - $500k</div>
                <div className="py-2 px-4 hover:bg-[var(--button-color)]">$500k - $1M</div>
                <div className="py-2 px-4 hover:bg-[var(--button-color)]">$1M+</div>
              </div>
            )}
          </div>

          {/* Dropdown: Property Size */}
          <div
            className="relative flex items-center justify-between gap-x-4  border border-solid border-[var(--border-color)] bg-[var(--primary-bg)] p-4 w-full max-w-xs cursor-pointer"
            onClick={() => handleDropdownToggle(3)}
          >
            <div className="flex items-center">
              <FaRuler className="h-6 w-6" />
              <div className="ml-3">Property Size</div>
            </div>
            <FiChevronDown className="h-6 w-6" />
            {dropdown === 3 && (
              <div className="absolute top-full left-0 w-full mt-1 bg-[var(--secondary-bg)]  shadow-lg text-[var(--primary-text)] z-50">
                <div className="py-2 px-4 hover:bg-[var(--button-color)]">500 sq ft</div>
                <div className="py-2 px-4 hover:bg-[var(--button-color)]">1000 sq ft</div>
                <div className="py-2 px-4 hover:bg-[var(--button-color)]">2000 sq ft</div>
              </div>
            )}
          </div>

          {/* Dropdown: Build Year */}
          <div
            className="relative flex items-center justify-between gap-x-4  border border-solid border-[var(--border-color)] bg-[var(--primary-bg)] p-4 w-full max-w-xs cursor-pointer"
            onClick={() => handleDropdownToggle(4)}
          >
            <div className="flex items-center">
              <FaCalendarAlt className="h-6 w-6" />
              <div className="ml-3">Build Year</div>
            </div>
            <FiChevronDown className="h-6 w-6" />
            {dropdown === 4 && (
              <div className="absolute top-full left-0 w-full mt-1 bg-[var(--secondary-bg)]  shadow-lg text-[var(--primary-text)] z-50">
                <div className="py-2 px-4 hover:bg-[var(--button-color)]">2020</div>
                <div className="py-2 px-4 hover:bg-[var(--button-color)]">2010</div>
                <div className="py-2 px-4 hover:bg-[var(--button-color)]">2000</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top;
