import React, { useState, useEffect, useRef } from "react";
import { FaArrowLeft, FaArrowRight, FaBed } from "react-icons/fa"; // Added FaBed icon
import AOS from 'aos';

import 'aos/dist/aos.css';

// Property data array with additional status and number of beds
const propertyData = [
  {
    title: "Coastal Escapes",
    description: "Seaside Serenity Villa",
    details: "Wake up to the soothing melody of waves.",
    price: "$1,250,000",
    status: "Active",
    beds: 4, // Added number of beds
    img: "https://images.unsplash.com/photo-1510627489930-0c1b0bfb6785?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Urban Oasis",
    description: "Metropolitan Haven",
    details: "Immerse yourself in the energy of the city.",
    price: "$650,000",
    status: "Active",
    beds: 3,
    img: "https://images.unsplash.com/photo-1568605114967-8130f3a36994?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Countryside Charm",
    description: "Rustic Retreat Cottage",
    details: "Find tranquility in the countryside.",
    price: "$350,000",
    status: "Active",
    beds: 2,
    img: "https://images.unsplash.com/photo-1570129477492-45c003edd2be?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Modern Loft",
    description: "Sleek City Loft",
    details: "Enjoy a modern, luxurious lifestyle",
    price: "$800,000",
    status: "Active",
    beds: 1,
    img: "https://images.unsplash.com/photo-1582268611958-ebfd161ef9cf?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Mountain Retreat",
    description: "Cabin in the Mountains",
    details: "Experience ultimate peace",
    price: "$500,000",
    status: "Active",
    beds: 3,
    img: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

const Featured = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const [cards, setCards] = useState(propertyData);
  const [isSliding, setIsSliding] = useState(false);
  const intervalRef = useRef(null);

  const nextSlide = () => {
    if (isSliding) return;

    setIsSliding(true);
    setTimeout(() => {
      const newCards = [...cards];
      const firstCard = newCards.shift();
      newCards.push(firstCard);
      setCards(newCards);
      setIsSliding(false);
    }, 10); // Smooth transition over 1 second
  };

  const prevSlide = () => {
    if (isSliding) return;

    setIsSliding(true);
    setTimeout(() => {
      const newCards = [...cards];
      const lastCard = newCards.pop();
      newCards.unshift(lastCard);
      setCards(newCards);
      setIsSliding(false);
    }, 10); // Smooth transition over 1 second
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(intervalRef.current);
  }, [cards]);

  return (
    <div className="text-sizes pb-24 w-full h-full bg-[var(--bg1)] py-10 px-16 flex flex-col items-center">
      <div className="text-center">
        <h1
          className="text-sizes3 leading-normal text-[var(--button-color)] flex justify-center"
          data-aos="fade-right"
          data-aos-delay="200"
          data-aos-duration="1500"
        >
          Exclusive Listing...
        </h1>
      </div>

      <div
        className="relative w-full max-w-[96rem] mt-8 border-[var(--border-color)] border-2 sm:py-12 sm:px-6"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="1500"
      >
        <div
          className={`flex overflow-hidden gap-8 transition-all duration-1000 ease-in-out transform ${isSliding ? "transition-transform" : ""
            }`}
        >
          {cards.slice(0, 3).map((property, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-full md:w-1/3 transition-transform duration-1000 transform border border-[var(--borderc)]"
            >
              <div className="bg-[var(--bg1)] rounded-lg p-6 flex flex-col items-start shadow-lg relative">
                {/* Image section with price overlay */}
                <div className="relative w-full">
                  <img
                    src={property.img}
                    alt={property.title}
                    className="w-full h-48 object-cover rounded-lg"
                  />
                  <p className="absolute bottom-2 right-2 text-lg font-semibold text-white bg-[var(--gold)] p-2 rounded">
                    {property.price}
                  </p>
                </div>

                <h2 className="text-sizes font-semibold  mt-4">
                  {property.title}
                </h2>
                <h3 className="text-sizes  mt-2">
                  {property.description}
                </h3>
                <p className="text-[var(--primary-text)] mt-3">
                  {property.details}
                </p>
                {/* Status and beds */}
                <p className="text-[var(--text1)] font-bold mt-3">
                  Status: {property.status}
                </p>
                <p className="flex items-center text-[var(--text1)] mt-2">
                  <FaBed className="mr-2" /> {property.beds} Beds
                </p>

                {/* More Details Button */}
                <p className="mt-4">
                  <button className=" px-4 py-2  bt4">
                    More Details
                  </button>
                </p>
              </div>
            </div>
          ))}
        </div>

        <button
          onClick={prevSlide}
          className="absolute top-1/2 -left-6 transform -translate-y-1/2 p-2 rounded-full bg-[var(--bg1)] border-2 border-black hover:bg-[var(--button-color-hover)]"
        >
          <FaArrowLeft className="text-[var(--text1)] text-lg" />
        </button>
        <button
          onClick={nextSlide}
          className="absolute top-1/2 -right-6 transform -translate-y-1/2 p-2 rounded-full bg-[var(--bg1)] border-2 border-black hover:bg-[var(--button-color-hover)]"
        >
          <FaArrowRight className="text-[var(--text1)] text-lg" />
        </button>
      </div>
    </div>
  );
};

export default Featured;
