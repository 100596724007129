import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import realestate from './realestate.jpg'
import { FaPhoneAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

const BuyGuide = () => {
  return (
    <div>
      <Navbar />

      <div className='px-12 md:px-36'>

        <div className='my-8'>

          <h1 className='text-sizes3 flex justify-center mb-8 text-[var(--text1)] '> A HASSLE FREE  GUIDE TO BUYING</h1>
          <p className='text-sizes2 text-justify'>Buying a home can be one of the grandest and happiest decisions of your life. It can also be one of the most complex and demanding decisions. To ready you for the upcoming transition, here is a general overview of what to expect and prepare.</p>


        </div>


        <div className='flex justify-center'>

          <img className='w-auto h-auto object-cover ' src={realestate} alt='Real Estate' />
        </div>
        <div className='my-8'>

          <h1 className='text-sizes2 flex justify-start text-justify mb-8 font-bold'>PREPARE YOUR FINANCING
          </h1>
          <p className='text-sizes2 text-justify'>Before anything else, you’ll need to have your finances prepared. When buying a house it is recommended that you have 20% prepared for downpayment (though 5% would be the minimum). You will also need to meet with a mortgage broker and get pre-approved in order to understand what you can afford before looking at properties. If you are unsure what mortgage brokerage to use, we will be happy to help refer one to you.</p>
        </div>
        <div className='my-8'>

          <h1 className='text-sizes2 flex justify-start mb-8 font-bold'>UNDERSTANDING YOUR LIFESTYLE</h1>
          <p className='text-sizes2 text-justify'>Finding the perfect home will be unique to your own lifestyle. What are the most important things to you in a home? A place for a home office, easy access to shops, or a view of the oceanfront? These are questions you need to know the answer to as we move forward to buying your dream home. The more you understand your own wants and needs, the better we can help you find the right home!</p>


        </div>
        <div className='my-8'>

          <h1 className='text-sizes2 flex justify-start mb-8 font-bold'>MEETING YOUR REALTOR</h1>
          <p className='text-sizes2 text-justify'>Finding the right realtor is just as important as finding the right property because it goes hand-in-hand with attaining that dream home! Your realtor should be someone who you can have full confidence in. They will be setting up your view appointments, negotiating your demands, and helping you navigate around any concerns you may have about this home-buying process. You’ll want to work with someone who represents your best interests and will actively reach out to you to keep you updated.</p>


        </div>
        <div className='my-8'>

          <h1 className='text-sizes2 flex justify-start mb-8 font-bold '>FINDING YOUR DREAM HOME</h1>
          <p className='text-sizes2 text-justify'>Upon discussing with your realtor what kind of home you are looking for and in which area, your real estate agent will prepare homes for you to view in your desired neighbourhood. They will continue to keep you updated on any new listings matching your home criteria and research and schedule any other homes you have any interest in.

            Our job as your realtor isn’t over until we’ve found and closed on that perfect home for you. Once you’ve found your dream home. We will help you place an offer that will either be accepted, countered, or rejected by the seller. We will sit with you and discuss how you’d like to negotiate on an offer, things such as conducting a home-inspection, dealing with repairs, and finalizing your mortgage. Once you’ve signed off on the ownership it’ll be smooth sailing from there!

            Congratulations! You are now a homeowner!</p>


        </div>





      </div>
<div className='px-12'>



<div className="flex flex-col items-center text-center py-10 px-12">
      {/* Heading */}
      <h2 className="text-sizes3 font-bold mb-4  text-[var(--gold)]">WE'D LOVE TO MEET YOU!</h2>

      {/* Description */}
      <p className="text-sizes2 max-w-2xl mb-6">
        Whether buying or selling a home, we’ll select one of our experienced agents to make your dream come true based on your lifestyle needs.
      </p>

      {/* Button */}
      <button className="bt1 ">
        CONTACT US
      </button>

      {/* Contact Info */}
      <div className="flex items-center space-x-10 mt-8 px-12 flex-col md:flex-row">
        <div className="flex items-center space-x-2">
          <FaPhoneAlt className="text-xl" />
          <span className="text-lg md:text-xl">604.385.3770</span>
        </div>
        <div className="flex items-center space-x-2">
          <MdEmail className="text-2xl" />
          <span className="text-lg md:text-xl">sarthak@gmail.com</span>
        </div>
      </div>
    </div>
</div>

     






      <Footer />
    </div>
  )
}

export default BuyGuide
