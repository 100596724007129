import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { blogs } from './Blog'; // Importing the blogs array
import Navbar from '../../Navbar';
import Footer from '../../Footer';

const BlogDetail = () => {
  const { title } = useParams();
  
  // Find the blog by matching the title from the URL params
  const blog = blogs.find((blog) => blog.title === title);

  if (!blog) {
    return (
      <div className="container mx-auto p-4 text-center">
        <h2 className="text-2xl font-bold mb-4">Blog Not Found</h2>
        <Link to="/blog" className="text-blue-500 hover:text-blue-700">
          Return to Blogs
        </Link>
      </div>
    );
  }

  return (
    <>
      <Navbar />

      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">{blog.title}</h1>
        <img src={blog.imageUrl} alt={blog.title} className="w-full h-64 object-cover mb-4 rounded-lg shadow-lg" />

        {/* Rendering blog content safely */}
        <div className="text-base leading-relaxed text-gray-800 mb-4" dangerouslySetInnerHTML={{ __html: blog.content }} />

        <Link to="/blog" className="bt1 text-blue-500 hover:text-blue-700">
          Return to Blogs
        </Link>
      </div>

      <Footer />
    </>
  );
};

export default BlogDetail;
