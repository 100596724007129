import React, { useState, useEffect, useRef } from "react";
import { FaArrowLeft, FaArrowRight, FaStar } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';

// Property data array with images and feedback
const propertyData = [
  {
    title: "Exceptional Service!",
    description: "Our experience with Estatein was outstanding. Their team's dedication and professionalism made finding our dream home a breeze. Highly recommended!",
    Name: "Wade Warren",
    country: "USA",
    img: "https://images.unsplash.com/photo-1510627489930-0c1b0bfb6785?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Efficient and Reliable",
    description: "Estatein provided us with top-notch service. They helped us sell our property quickly and at a great price. We couldn't be happier with the results.",
    Name: "Emelie Thomson",
    country: "Canada",
    img: "https://images.unsplash.com/photo-1568605114967-8130f3a36994?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Trusted Advisors",
    description: "The Estatein team guided us through the entire buying process. Their knowledge and commitment to our needs were impressive. Thank you for your support!",
    Name: "John Mans",
    country: "India",
    img: "https://images.unsplash.com/photo-1570129477492-45c003edd2be?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Trusted Advisors",
    description: "The Estatein team guided us through the entire buying process. Their knowledge and commitment to our needs were impressive. Thank you for your support!",
    Name: "John Mans",
    country: "India",
    img: "https://images.unsplash.com/photo-1570129477492-45c003edd2be?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Trusted Advisors",
    description: "The Estatein team guided us through the entire buying process. Their knowledge and commitment to our needs were impressive. Thank you for your support!",
    Name: "John Mans",
    country: "India",
    img: "https://images.unsplash.com/photo-1570129477492-45c003edd2be?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Trusted Advisors",
    description: "The Estatein team guided us through the entire buying process. Their knowledge and commitment to our needs were impressive. Thank you for your support!",
    Name: "John Mans",
    country: "India",
    img: "https://images.unsplash.com/photo-1570129477492-45c003edd2be?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

const Testimonial = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const [cards, setCards] = useState(propertyData);
  const [isSliding, setIsSliding] = useState(false);
  const intervalRef = useRef(null);

  const nextSlide = () => {
    if (isSliding) return;
    setIsSliding(true);
    setTimeout(() => {
      const newCards = [...cards];
      const firstCard = newCards.shift();
      newCards.push(firstCard);
      setCards(newCards);
      setIsSliding(false);
    }, 100);
  };

  const prevSlide = () => {
    if (isSliding) return;
    setIsSliding(true);
    setTimeout(() => {
      const newCards = [...cards];
      const lastCard = newCards.pop();
      newCards.unshift(lastCard);
      setCards(newCards);
      setIsSliding(false);
    }, 100);
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(intervalRef.current);
  }, [cards]);

  return (
    <div className="text-sizes w-full h-full bg-[var(--primary-bg)] py-10 px-12 flex flex-col items-center mt-12 mb-12">
      {/* Header Section */}
      <div className="text-center">
        <h1 className="text-sizes3 leading-normal text-[var(--text1)] flex justify-center" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500">
          Client Feedbacks
        </h1>
      </div>

      {/* Carousel Section */}
      <div className="relative w-full max-w-[96rem] mt-8  sm:py-12 sm:px-12" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
        {/* Cards */}
        <div className={`flex overflow-hidden gap-4 transition-all duration-1000 ease-in-out transform ${isSliding ? "transition-transform" : ""}`}>
          {cards.slice(0, 4).map((property, index) => (
            <div key={index} className="flex-shrink-0 w-full md:w-1/3 xl:w-1/4 transition-transform duration-500 transform border border-[var(--borderc)]">
              <div className="bg-[var(--secondary-bg)] rounded-lg p-6 flex flex-col items-start shadow-lg">
                {/* Image, Name and Country in a Row */}
                <div className="flex items-center mb-4">
                  {/* Larger Circular Image */}
                  <img
                    src={property.img}
                    alt={property.title}
                    className="w-20 h-20 object-cover rounded-full" // Increased image size (w-20, h-20)
                  />
                  <div className="ml-4">
                    <p className="text-[var(--primary-text)] font-semibold text-lg">{property.Name}</p>
                    <p className="text-[var(--button-color)] font-medium">{property.country}</p>
                  </div>
                </div>

                {/* Rating Stars */}
                <div className="flex space-x-1 mb-2">
                  {[...Array(5)].map((_, i) => (
                    <FaStar key={i} className="text-yellow-500" />
                  ))}
                </div>

                {/* Title and Description */}
                <h2 className="text-sizes font-semibold text-[var(--primary-text)] mt-2">{property.title}</h2>
                <h3 className="text-sizes text-[var(--primary-text)] mt-2 mb-4 text-justify">{property.description}</h3>
              </div>
            </div>
          ))}
        </div>

        {/* Navigation Arrows */}
        <button onClick={prevSlide} className="absolute top-1/2 -left-6 transform -translate-y-1/2 p-2 rounded-full bg-[var(--bg1)] border-2 border-[var(--text1)] ">
          <FaArrowLeft className="text-[var(--text1)] text-lg" />
        </button>
        <button onClick={nextSlide} className="absolute top-1/2 -right-6 transform -translate-y-1/2 p-2 rounded-full bg-[var(--bg1)] border-2 border-[var(--text1)]  ">
          <FaArrowRight className="text-[var(--text1)]  text-lg" />
        </button>
      </div>

      {/* Pagination */}
      <div className="flex space-x-2 mt-6">
        {propertyData.map((_, index) => (
          <div key={index} className={`w-3 h-3 rounded-full ${index === 0 ? "bg-[var(--button-color)]" : "bg-neutral-400"}`}></div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
