import {React,useEffect} from 'react';
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles

const Form = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
    });
  }, []);
  return (
    <div className="flex flex-col items-center justify-center px-12 sm:px-12 py-12 bg-[var(--primary-bg)] border-[var(--border-color)] sm:py-8  md:py-12 md:px-12  lg:py-12 lg:px-12 xl:py-12 2xl:py-12">
      {/* First Section */}
      <section className="w-full mx-auto border-[var(--border-color)] border-2  m-4 sm:m-16 px-6 sm:px-12 py-12">
        <h1 className="text-sizes2 text-[var(--button-color)]" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500">
         Get in Touch
        </h1>
        <h1 className="text-sizes text-[var(--primary-text)] pt-3 mr-4" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
        Reach out to our dedicated team and we'll help answer any questions.

</h1>
        <form className="py-8 sm:py-16">
          <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {/* Username Input */}
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="username">
                First Name
              </label>
              <input
                id="username"
                type="text" required
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              />
            </div>
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="username">
                Last Name
              </label>
              <input
                id="username"
                type="text"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              />
            </div>

            {/* Email Address Input */}
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="emailAddress">
                Email
              </label>
              <input
                id="emailAddress" required
                type="email"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)] border border-[var(--border-color)] focus:outline-none focus:ring"
              />
            </div>
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="phone">
                Phone Number
              </label>
              <input
                id="phone"
                type="tel" required
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              />
            </div>

            {/* Select Input */}
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="select">
                Looking For
              </label>
              <select
                id="select"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              >
                <option>Investing</option>
                <option>Buying a House</option>
                <option>Commerical </option>
                <option>Selling My House</option>
              </select>
            </div>

          

            {/* Date Input */}
            {/* <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="date">
                Today's Date
              </label>
              <input
                id="date"
                type="date"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              />
            </div> */}

            {/* Text Area Input */}
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="textarea">
               Addititonal Information
              </label>
              <textarea
                id="textarea"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              ></textarea>
            </div>
          </div>

          {/* Save Button */}
          <div className="flex justify-end mt-6">
            <button className="px-6 py-2 leading-5 text-[var(--primary-text)] bt1">
              Send your message
            </button>
          </div>
        </form>
      </section>
    </div>

  );
};

export default Form;
