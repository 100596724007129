import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import sarthaksell from './sarthaksell.jpeg'

const SellGuide = () => {
  return (
    <>

      <Navbar />




      <div className="container mx-auto flex flex-col lg:flex-row items-center py-10 px-12 text-justify ">
        {/* Image Section */}
        <div className="lg:w-1/2 w-auto  h-auto flex justify-center lg:justify-center mb-8 lg:mb-0 ">

        <div className='border-4 border-[var(--gold)] p-2'>   <img
            src={sarthaksell}
            alt="Real Estate Agent"
            className=" shadow-lg max-w-xs lg:max-w-sm  border-2 border-black"
          /></div>
       
        </div>

        {/* Text Content Section */}
        <div className="lg:w-1/2 w-full text-left lg:pl-10 font-semibold ">
          <h2 className="text-sizes3 font-medium  mb-2 font2">SELL WITH</h2>
          <h1 className="text-sizes3 font-bold mb-8 font2">RAYUP REAL ESTATE GROUP</h1>
          <p className="text-sizes2  mb-8 font2 font-semibold ">
            Selling your home is a major decision that requires careful consideration.
          </p>
          <p className=" text-sizes2  mb-8 font2 font-semibold ">
            You've invested years into your property, and now it's time to maximize that investment. From setting a competitive price to creating an effective marketing strategy, there are many factors that contribute to a successful sale. You deserve an agent dedicated to elevating your listing.
          </p>
          <p className=" text-sizes2  mb-8 font2 font-semibold ">
            When you choose to work with us, you gain access to the extensive Sarthak Sharma network of industry professionals.
          </p>
          <p className="text-sizes2  mb-8 font2 font-semibold ">
            We are committed to meeting all your real estate needs in one place.
          </p>

          {/* Call to Action Buttons */}
        
        </div>
      </div>

      <Footer />

    </>


  );
};

export default SellGuide;
