import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaInstagram, FaYoutube, FaFacebook } from 'react-icons/fa';
import sarthak1 from './sarthak1.jpeg'

const RealEstateProfile = () => {
  return (
    <div className="container mx-auto flex flex-col lg:flex-row items-center py-10 px-12  lg:gap-24 ">
      {/* Image Section */}
      <div className="md:w-1/3 w-full flex justify-center lg:justify-start mb-8 lg:mb-0">
      <div className='border-4 border-[var(--gold)] p-2'>  
         <img
            src={sarthak1}
            alt="Real Estate Agent"
            className=" shadow-lg max-w-xs lg:max-w-sm  border-2 border-black"
          /></div>
      </div>

      {/* Text Content Section */}
      <div className="lg:w-2/3 w-full text-left lg:pl-10">
        <h1 className="text-sizes3 font-bold mb-2">SARTHAK SHARMA</h1>
        <h3 className="text-sizes2  text-[var(--gold)] mb-4">PERSONAL REAL ESTATE CORPORATION</h3>
        
        <p className="text-sizes2 text-gray-700 mb-6 font-normal">
          From serving tables 15 years ago as a new immigrant, to owning a multi-million dollar business as a family man. My success is built on my dedication to helping investors achieve their real estate investment goals.
        </p>
        <p className="text-sizes2 text-gray-700 mb-6 font-normal">
          I believe that everyone should have equal right to information and opportunity to secure their own wealth. In a digital age, the information is out there, but it may be hard to find direction.
        </p>
      
        <p className="text-sizes2 text-gray-700 mb-6 font-normal">
          Realtors should do more than open doors and put a “for sale” sign out. My job is to nurture your investments and to be your guide from start to finish.
        </p>
        <p className="font-bold text-sizes2 text-gray-800 mb-6 font-normal">
          Save time for yourself, seize financial efficiency, and work with Sarthak Sharma.
        </p>

        {/* Contact Info */}
        <div className="flex items-start md:items-center mb-6 space-x-4 flex-col md:flex-row ">
          <div className="flex items-center space-x-2">
            <FaPhoneAlt className="text-gray-600" />
            <span className="text-lg text-gray-800">604.385.3770</span>
          </div>
          <div className="flex items-center space-x-2">
            <FaEnvelope className="text-gray-600" />
            <span className="text-lg text-gray-800">Sarthak@gmail.com</span>
          </div>
        
        </div>

        {/* CTA Button */}
        <button className="bt3">
          VIEW PROPERTIES
        </button>
      </div>
    </div>
  );
};

export default RealEstateProfile;
