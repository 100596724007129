import React from 'react';
import { FaHouseCircleCheck } from "react-icons/fa6";
import { PiCardsFill } from "react-icons/pi";
import { BiSolidBuildings } from "react-icons/bi";
import { FaLandmark } from "react-icons/fa";
import { MdOutlineAttachMoney } from "react-icons/md";
import { Link } from 'react-router-dom';
import { LuConstruction } from "react-icons/lu";


const Tabs = () => {
  return (
    <div>
      <div className="flex flex-wrap md:flex-nowrap justify-center bg-[var(--bg1)] px-12 pt-24 pb-24 font-semibold relative">
        <div
          className="bg-[var(--bg1)] border-2 py-8 px-8 md:px-4 border-[var(--borderc)] flex items-center justify-center flex-col m-2 w-[calc(50%-1rem)] md:w-1/4 hovbox"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="500"
        >
          <FaHouseCircleCheck className="text-sizes3 text-[var(--gold)] mb-4" />
          <p className="text-[var(--primary-text)] text-sizes">Find your dream Home</p>
        </div>

        <div
          className="bg-[var(--bg1)] border-2 py-8 px-8 md:px-4 border-[var(--borderc)] flex items-center justify-center flex-col m-2 w-[calc(50%-1rem)] md:w-1/4 hovbox"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="500"
        >

          <PiCardsFill className="text-sizes3 text-[var(--gold)] mb-4" />

          <Link to='/evaluation'> 
          <p className="text-[var(--primary-text)] text-sizes">Looking to sell / Request a free property evaluation</p>

          </Link>
        </div>



        <div
          className="bg-[var(--bg1)] border-2 py-8 px-8 md:px-4 border-[var(--borderc)] flex items-center justify-center flex-col m-2 w-[calc(50%-1rem)] md:w-1/4 hovbox"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-duration="500"
        >
          <FaLandmark className="text-sizes3 text-[var(--gold)] mb-4" />
          <p className="text-[var(--primary-text)] text-sizes">Land for Sale</p>
        </div>

        <div
          className="bg-[var(--bg1)] border-2 py-8 px-8 md:px-4 border-[var(--borderc)] flex items-center justify-center flex-col m-2 w-[calc(50%-1rem)] md:w-1/4 hovbox"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-duration="500"
        >
          <MdOutlineAttachMoney className="text-sizes3 text-[var(--gold)] mb-4" />
          <p className="text-[var(--primary-text)] text-sizes">Looking to Invest in Ontario</p>
        </div>
        <div
          className="bg-[var(--bg1)] border-2 py-8 px-8 md:px-4 border-[var(--borderc)] flex items-center justify-center flex-col m-2 w-[calc(50%-1rem)] md:w-1/4 hovbox"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-duration="500"
        >
          <LuConstruction className="text-sizes3 text-[var(--gold)] mb-4" />
          <p className="text-[var(--primary-text)] text-sizes">Pre-Construction Projects</p>
        </div>


        <div
          className="bg-[var(--bg1)] border-2 py-8 px-8 md:px-4 border-[var(--borderc)] flex items-center justify-center flex-col m-2 w-[calc(50%-1rem)] md:w-1/4 hovbox relative group"
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-duration="500"
        >
          <BiSolidBuildings className="text-sizes3 text-[var(--gold)] mb-4" />
          <p className="text-[var(--primary-text)] text-sizes">Commercial</p>

          <div className="absolute left-0 top-full mt-2 w-full bg-[var(--bg2)] border border-[var(--borderc)] hidden group-hover:block z-20">
            <ul className="flex flex-col text-[var(--text2)]">

              <Link to='/business'>   <li className="py-2 px-4 hover:bg-gray-950">Business For Sale</li>
              </Link>
              <Link to='/commercial' >     <li className="py-2 px-4 hover:bg-gray-950">Commercial Properties For Sale</li>
              </Link>

            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tabs;
