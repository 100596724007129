// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Importing necessary components from react-router-dom
import Home from './components/Home/Home';
import Contact from './components/Contact/Contact';
import Properties from './components/Properties/Properties';
import About from './components/AboutUs/About';
import Mortgage from './components/Insights/Mortgage';
import BuyGuide from './components/Insights/BuyGuide';
import SellGuide from './components/Insights/SellGuide';
import BlogList from './components/Insights/Blog/BlogList';
import BlogDetail from './components/Insights/Blog/BlogDetail';
import BlogCard from './components/Insights/Blog/BlogCard';
import Business from './components/Home/Commercialtab/Business';
import Commercial from './components/Home/Commercialtab/Commercial';
import Evaluation from './components/Forms/Evaluation';


const App = () => {
  return (
    <Router>
      {/* Wrapping everything in BrowserRouter */}
      <Routes>
        {/* Define Routes for different paths */}
        <Route path="/" element={<Home />} /> {/* Home route */}
        <Route path="/properties" element={<Properties />} /> {/* Properties route */}
        <Route path="/about" element={<About />} /> {/* Properties route */}
        <Route path="/contact" element={<Contact />} /> {/* Contact route */}
        <Route path="/mortgages" element={<Mortgage />} /> {/* Contact route */}
        <Route path="/buyer" element={< BuyGuide />} /> {/* Contact route */}
        <Route path="/seller" element={< SellGuide />} /> {/* Contact route */}
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blogcard" element={<BlogCard />} />
        <Route path="/:title" element={<BlogDetail />} />
        <Route path="/business" element={<Business />} />
        <Route path="/commercial" element={<Commercial />} />
        <Route path="/evaluation" element={<Evaluation />} />

      </Routes>
    </Router>
  );
};

export default App;
