import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Value from './Value'
import Profile from './Profile'

const About = () => {
  return (
    <div>
      <Navbar />
      <Profile />
      <Value />
      <Footer />



    </div>
  )
}

export default About
