// BlogCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import blog from './Blog'

const BlogCard = ({ blog }) => {
  const { title, description, imageUrl } = blog;

  return (
    <div className="hovbox bg-white shadow-md rounded-md overflow-hidden mb-6">
      <img src={imageUrl} alt={title} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h2 className="text-sizes2 font-bold mb-2">{title}</h2>
        <p className="text-sizes text-gray-600">{description}</p>
        <Link to={`/${title}`} className="text-blue-500 hover:text-blue-700">
          Read More
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
