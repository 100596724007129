import React from 'react'
import Navbar from '../../Navbar'
import Footer from '../../Footer'

const Commercial = () => {
  return (

    <>
      <Navbar />
      

      <Footer />


    </>

  )
}

export default Commercial
