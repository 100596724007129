import {React,useEffect} from 'react'
import { AiOutlineMail, AiOutlinePhone, AiOutlineEnvironment, AiOutlineShareAlt } from 'react-icons/ai';
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles


const Details = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
    });
  }, []);
  return (
    <div>

      {/* top section */}
      <div className='bg-[var(--primary-bg)] text-[var(--primary-text)] py-4 px-12 sm:py-8 sm:px-12 md:py-12 md:px-12  lg:py-12 lg:px-12 xl:py-12 2xl:py-12 '>
        <h1 className='text-sizes2 mb-4 text-[var(--button-color)]' data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500">Get in Touch with RayupRealEstate</h1>
        <p className='text-sizes  mr-[2rem] border-[var(--border-color)]' data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
          Welcome to RayupRealEstate's Contact Us page. We're here to assist you with any inquiries, requests, or feedback you may have. Whether you're looking to buy or sell a property, explore investment opportunities, or simply want to connect, we're just a message away. Reach out to us, and let's start a conversation.
        </p>
      </div>


      {/* 4-box section */}

      <div className='flex flex-wrap md:flex-nowrap justify-center bg-[var(--primary-bg)] text-[var(--primary-text)] px-12  sm:py-8 sm:px-12 md:py-12 md:px-12  lg:py-12 lg:px-12 xl:py-12 2xl:py-12'>
        {/* Email */}
        <div className='bg-[var(--secondary-bg)] border-2  py-8 px-8 md:px-4 border-[var(--border-color)] flex items-center justify-center flex-col m-2 w-[calc(50%-1rem)] md:w-1/4 hovbox' data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500">
          <AiOutlineMail className='text-sizes3 text-[var(--gold)]  mb-4' /> {/* Mail Icon */}
          <p className='text-[var(--text1)] text-sizes'>Rayup@example.com</p>
        </div>

        {/* Phone */}
        <div className='bg-[var(--secondary-bg)] border-2  py-8 px-8 md:px-4 border-[var(--border-color)] flex items-center justify-center flex-col m-2 w-[calc(50%-1rem)] md:w-1/4 hovbox' data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
          <AiOutlinePhone className='text-sizes3 text-[var(--gold)] mb-4' /> {/* Phone Icon */}
          <p className='text-[var(--text1)]  text-sizes'>+123-456-7890</p>
        </div>

        {/* Location */}
        <div className='bg-[var(--secondary-bg)] border-2  py-8 px-8 md:px-4 border-[var(--border-color)] flex items-center justify-center flex-col m-2 w-[calc(50%-1rem)] md:w-1/4 hovbox' data-aos="fade-up" data-aos-delay="500" data-aos-duration="1500">
          <AiOutlineEnvironment className='text-sizes3 text-[var(--gold)] mb-4' /> {/* Location Icon */}
          <p className='text-[var(--text1)]  text-sizes'>Canada</p>
        </div>

        {/* Social Media */}
        <div className='bg-[var(--secondary-bg)] border-2 py-8 px-8 md:px-4 border-[var(--border-color)] flex items-center justify-center flex-col m-2 w-[calc(50%-1rem)] md:w-1/4 hovbox' data-aos="fade-up" data-aos-delay="700" data-aos-duration="1500">
          <AiOutlineShareAlt className='text-sizes3 text-[var(--gold)] mb-4' /> {/* Social Media Icon */}
          <p className='text-[var(--text1)]  text-sizes'>@socialhandle</p>
        </div>
      </div>

    </div>
  )
}

export default Details
