import React, { useState, useEffect, useRef } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles

// Property data array with images from Unsplash
const propertyData = [
  {
    title: "Coastal Escapes ",
    description: "Seaside Serenity Villa",
    details: "Wake up to the soothing melody of waves. ",
    price: "$1,250,000",
    img: "https://images.unsplash.com/photo-1510627489930-0c1b0bfb6785?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Urban Oasis ",
    description: "Metropolitan Haven",
    details: "Immerse yourself in the energy of the city. ",
    price: "$650,000",
    img: "https://images.unsplash.com/photo-1568605114967-8130f3a36994?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Countryside Charm",
    description: "Rustic Retreat Cottage",
    details: "Find tranquility in the countryside. ",
    price: "$350,000",
    img: "https://images.unsplash.com/photo-1570129477492-45c003edd2be?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Modern Loft",
    description: "Sleek City Loft",
    details: "Enjoy a modern, luxurious lifestyle  ",
    price: "$800,000",
    img: "https://images.unsplash.com/photo-1582268611958-ebfd161ef9cf?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Mountain Retreat",
    description: "Cabin in the Mountains",
    details: "Experience ultimate peace  ",
    price: "$500,000",
    img: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

const Prop = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
    });
  }, []); 

  const [cards, setCards] = useState(propertyData); // Carousel data
  const [isSliding, setIsSliding] = useState(false); // To prevent continuous clicking
  const intervalRef = useRef(null); // For auto-sliding

  // Function to handle right/next slide
  const nextSlide = () => {
    if (isSliding) return; // Prevent multiple clicks during animation

    setIsSliding(true); // Set sliding to true
    setTimeout(() => {
      const newCards = [...cards];
      const firstCard = newCards.shift(); // Remove the first card
      newCards.push(firstCard); // Add it to the end
      setCards(newCards); // Update the card list
      setIsSliding(false); // Sliding is done
    }, 100); // Transition duration (matches CSS)
  };

  // Function to handle left/prev slide
  const prevSlide = () => {
    if (isSliding) return; // Prevent multiple clicks during animation

    setIsSliding(true); // Set sliding to true
    setTimeout(() => {
      const newCards = [...cards];
      const lastCard = newCards.pop(); // Remove the last card
      newCards.unshift(lastCard); // Add it to the front
      setCards(newCards); // Update the card list
      setIsSliding(false); // Sliding is done
    }, 100); // Transition duration (matches CSS)
  };

  // Automatically slide every 5 seconds
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      nextSlide();
    }, 5000); // 5 seconds

    return () => clearInterval(intervalRef.current); // Cleanup interval
  }, [cards]);

  return (
    <div  className="text-sizes w-full h-full bg-[var(--primary-bg)] py-10 px-16 flex flex-col items-center">
      {/* Header Section */}
      <div className="text-center">
        <h1 className="text-sizes2  leading-normal text-[var(--button-color)] flex justify-center" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500" >
         Properties after Search ...
        </h1>
      </div>

      {/* Carousel Section */}
      <div className="relative w-full max-w-[96rem] mt-8 border-[var(--border-color)] border-2 sm:py-12 sm:px-6 " data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
        {/* Cards */}
        <div
          className={`flex overflow-hidden gap-8 transition-all duration-1000 ease-in-out transform  ${
            isSliding ? "transition-transform" : ""
          }`}
        >
          {cards.slice(0, 3).map((property, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-full md:w-1/3 transition-transform duration-100 transform border border-[var(--border-color)]"
            >
              <div className="bg-[var(--secondary-bg)] rounded-lg p-6 flex flex-col items-start shadow-lg">
                <img
                  src={property.img}
                  alt={property.title}
                  className="w-full h-48 object-cover rounded-lg"
                />
                <h2 className="text-sizes font-semibold text-[var(--primary-text)] mt-4">
                  {property.title}
                </h2>
                <h3 className="text-sizes text-[var(--primary-text)] mt-2">
                  {property.description}
                </h3>
                <p className="text-[var(--primary-text)]  mt-3">{property.details}</p>
                <p className="text-[var(--button-color)]  font-semibold mt-4">
                  Price: {property.price}
                </p>
                <p className="btn1 mt-2">
                  <button>View Property Details</button>
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Navigation Arrows */}
        <button
          onClick={prevSlide}
          className="absolute top-1/2 -left-6 transform -translate-y-1/2 p-2 rounded-full bg-[var(--button-color)] hover:bg-[var(--button-color-hover)]"
        >
          <FaArrowLeft className="text-white text-lg" />
        </button>
        <button
          onClick={nextSlide}
          className="absolute top-1/2 -right-6 transform -translate-y-1/2 p-2 rounded-full bg-[var(--button-color)] hover:bg-[var(--button-color-hover)]"
        >
          <FaArrowRight className="text-white text-lg" />
        </button>
      </div>

      {/* Pagination */}
      {/* <div className="flex space-x-2 mt-6">
        {propertyData.map((_, index) => (
          <div
            key={index}
            className={`w-3 h-3 rounded-full ${
              index === 0 ? "bg-[var(--button-color)]" : "bg-neutral-400"
            }`}
          ></div>
        ))}
      </div> */}
    </div>
  );
};

export default Prop;
