import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Details from './Details'
import Form from './Form'

const Contact = () => {
  return (
    <div>
      <Navbar />
    
      <Details />
      <Form />

      <Footer />



    </div>
  )
}

export default Contact
