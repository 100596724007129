import { React, useEffect, useState } from 'react';
import house3new from './house3new.jpg';
import newhome from './newhome.jpg'
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles

export default function HomeInfo() {
  const [bgImageLoaded, setBgImageLoaded] = useState(false); // State for lazy loading

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
    });

    const image = new Image();
    image.src = house3new;
    image.onload = () => setBgImageLoaded(true);
  }, []);

  return (
    <>
      {/* Main Wrapper for Background and "Looking For" Content */}
      <div className="relative w-full min-h-screen flex items-center  overflow-hidden justify-center items-center">
        {/* Background Image */}
        <div
          className={`absolute inset-0 h-full w-full bg-cover bg-center opacity-90 transition-opacity duration-500 ${bgImageLoaded ? 'opacity-100' : 'opacity-0'
            }`}
          style={{ backgroundImage: bgImageLoaded ? `url(${newhome})` : 'none' }}
        ></div>

        {/* Main Content Section */}
        <div className="relative z-10 w-full min-h-screen max-w-screen-2xl px-12 py-12 md:px-12 md:py-24 flex justify-center items-center">
  <div className="flex flex-col text-sizes font-medium leading-normal text-[var(--text2)] justify-center items-center">
    <h1
      className="text-sizes3 leading-[1.6] text-[var(--primary-text)] text-center font-bold font2 tracking-[1.125rem] px-12"
      data-aos="fade-down"
      data-aos-delay="200"
      data-aos-duration="1500"
    >
      RAYUP REAL ESTATE INC.
    </h1>
  </div>

        </div>
      </div>

    </>
  );
}
