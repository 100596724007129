import React, { useState } from 'react';

import Navbar from '../Navbar';
import Footer from '../Footer';
const Mortgage = () => {
  const [propertyPrice, setPropertyPrice] = useState('');
  const [downPayment, setDownPayment] = useState('');
  const [loanTerm, setLoanTerm] = useState(30); // default to 30 years
  const [interestRate, setInterestRate] = useState('');
  const [showCalculations, setShowCalculations] = useState(false);
  const [monthlyPayment, setMonthlyPayment] = useState(null);

  // Calculate Mortgage Payment
  const calculateMortgage = (e) => {
    e.preventDefault();

    const principal = propertyPrice - downPayment; // Loan amount (Principal)
    const numberOfPayments = loanTerm * 12; // Total number of months
    const monthlyInterestRate = interestRate / 100 / 12; // Monthly interest rate

    // Monthly payment formula: M = P[r(1+r)^n]/[(1+r)^n – 1]
    const monthlyPayment = principal * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
      (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

    setMonthlyPayment(monthlyPayment);
    setShowCalculations(true);
  };

  return (

    <>

      <Navbar />

      <div className="max-w-6xl mx-auto py-12 px-6">
        <h1 className=" text-3xl md:text-6xl font-bold mb-8 text-center">How Much House Can You Afford?</h1>

        <form onSubmit={calculateMortgage} className="space-y-8 ">
          {/* Property Price */}
          <div className="flex flex-col md:flex-row justify-between space-y-8 md:space-y-0 ">
            <div className="w-full md:w-1/2">
              <label htmlFor="propertyPrice" className="block text-sizes2 font-semibold mb-1">Property Price *</label>
              <input
                type="number"
                id="propertyPrice"
                className="w-full border-2 border-gray-300 px-4 py-4"
                placeholder="$2,000,000.00"
                value={propertyPrice}
                onChange={(e) => setPropertyPrice(Number(e.target.value))}
                required
              />
            </div>

            {/* Down Payment */}
            <div className="w-full md:w-1/2">
              <label htmlFor="downPayment" className="block text-sizes2 font-semibold mb-1">Down Payment *</label>
              <input
                type="number"
                id="downPayment"
                className="w-full border-2 border-gray-300 px-4 py-4"
                placeholder="$500,000.00"
                value={downPayment}
                onChange={(e) => setDownPayment(Number(e.target.value))}
                required
              />
            </div>
          </div>

          {/* Length of Mortgage */}
          <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 ">
            <div className="w-full md:w-1/2">
              <label htmlFor="loanTerm" className="block text-sizes2 font-semibold mb-1">Length of Mortgage *</label>
              <input
                type="number"
                id="loanTerm"
                className="w-full border-2 border-gray-300 px-4 py-4"
                placeholder="30 Years"
                value={loanTerm}
                onChange={(e) => setLoanTerm(Number(e.target.value))}
                required
              />
            </div>

            {/* Annual Interest Rate */}
            <div className="w-full md:w-1/2">
              <label htmlFor="interestRate" className="block text-sizes2 font-semibold mb-1">Annual Interest Rate *</label>
              <input
                type="number"
                step="0.01"
                id="interestRate"
                className="w-full border-2 border-gray-300 px-4 py-4"
                placeholder="3%"
                value={interestRate}
                onChange={(e) => setInterestRate(Number(e.target.value))}
                required
              />
            </div>
          </div>

          {/* Checkbox */}
          <div className="flex items-center">
            <input
              type="checkbox"
              id="showCalculations"
              className="w-4 h-4"
              checked={showCalculations}
              onChange={(e) => setShowCalculations(e.target.checked)}
            />
            <label htmlFor="showCalculations" className="ml-2 text-sm font-medium">Show me the calculations and amortization</label>
          </div>

          <div>
            THIS MORTGAGE CALCULATOR CAN BE USED TO FIGURE OUT MONTHLY PAYMENTS OF A HOME MORTGAGE LOAN, BASED ON THE HOME'S SALE PRICE, THE TERM OF THE LOAN DESIRED, BUYER'S DOWN PAYMENT PERCENTAGE, AND THE LOAN'S INTEREST RATE
          </div>

          {/* Button */}
          <div className="text-center">
            <button type="submit" className="bg-black text-white py-3 px-6 uppercase font-semibold hover:bg-gray-700 transition">
              Calculate
            </button>
          </div>
        </form>

        {/* Results */}
        {showCalculations && monthlyPayment !== null && (
          <div className="mt-8 text-center">
            <h2 className="text-2xl font-bold">Your Monthly Payment</h2>
            <p className="text-xl mt-4">${monthlyPayment.toFixed(2)}</p>
          </div>
        )}
      </div>


      <Footer />

    </>

  );
};

export default Mortgage;
