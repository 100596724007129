// blog.js
export const blogs = [
  {
    title: "HOUSE HACKING EXPLAINED",
    description: "<p style='color:blue;'>A deep dive into the trends of <strong>Alberta's real estate sector</strong>...</p>",
    imageUrl: "https://via.placeholder.com/300",
    content: "<p style='color:blue;'>A deep dive into the trends of <strong>Alberta's real estate sector</strong>...</p>",
  },
  {
    title: "First-Time Home Buyers in Canada",
    description: "Tips and tricks for first-time buyers navigating the Canadian real estate market...",
    imageUrl: "https://via.placeholder.com/300",
    content: "Full content of the blog goes here...",
  },
  {
    title: "Investment Opportunities in Alberta",
    description: "Why Alberta is becoming a hotspot for real estate investment...",
    imageUrl: "https://via.placeholder.com/300",
    content: "Full content of the blog goes here...",
  },
  {
    title: "Investment Opportunities in Alberta",
    description: "Why Alberta is becoming a hotspot for real estate investment...",
    imageUrl: "https://via.placeholder.com/300",
    content: "Full content of the blog goes here...",
  },

];
