import React from 'react';
import sarthak from './sarthak.jpeg'
import { Link } from 'react-router-dom';

const TeamSection = () => {
  return (
    <div className="bg-[var(--bg2)] py-24 px-12 mx-12 flex flex-col md:flex-row items-center justify-center my-18">
      {/* Image Section */}
      <div className="md:w-1/2 w-full p-4">
        <img
          src={sarthak} // Replace with the correct image path
          alt="Team"
          className="   w-[100%] md:w-[8
          0%] h-auto object-cover items-center justify-center"
        />
      </div>

      {/* Text Section */}
      <div className="md:w-1/2 w-full text-center md:text-left text-[var(--text2)] mt-8 md:mt-0 md:ml-10">
        <h2 className="text-sizes2  mb-6 md:mr-12 leading-8">
          AT RAYUPREALESTATE GROUP, WE ARE A TEAM OF EXPERT ADVISORS WITH A VAST NETWORK OF INDUSTRY PROFESSIONALS SO THAT ALL YOUR REAL ESTATE NEEDS ARE UNDER ONE ROOF.
        </h2>

        {/* Button */}

        <Link to='/contact'> <button className="bt3 mt-6">
          CONNECT WITH SARTHAK
        </button></Link>


      </div>
    </div>
  );
};

export default TeamSection;
