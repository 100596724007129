import { FaHandHoldingHeart, FaStar, FaUserFriends, FaHandshake } from 'react-icons/fa';
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles
import { React, useEffect } from 'react';

export default function Value() {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
    });
  }, []);
  return (
    <div className="flex w-full flex-col items-center bg-[var(--primary-bg)] py-6 px-12 text-sizes sm:py-8 sm:px-12 md:py-12 md:px-12  lg:py-6 lg:px-6 xl:py-20 2xl:py-24">
      <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between w-full lg:w-[90%] gap-10 lg:gap-16">
        {/* Our Values Section */}
        <div className="flex flex-col items-center lg:items-start gap-4 text-center lg:text-left lg:w-[35%]   ">
          <div className="flex items-center gap-2 ">
            <FaHandshake className="text-[var(--button-color)]" />
            <FaStar className="text-[var(--button-color)]" />
          </div>
          <h2 className="text-sizes2 text-[var(--button-color)]" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500">Our Values</h2>
          <p className="text-sizes text-[var(text-color)] max-w-[537px] lg:max-w-none" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500">
            Our story is one of continuous growth and evolution. We started as a small team with big dreams, determined to create a real estate platform that transcended the ordinary.
          </p>
        </div>

        {/* Cards Section */}
        <div className="flex flex-col lg:w-[65%] gap-y-7 lg:pl-4">
          {/* First Row of Cards */}
          <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between gap-y-7 lg:gap-x-7 w-full">
            {/* Card 1 */}
            <div className="flex flex-col items-center bg-[var(--secondary-bg)] border border-[var(--border-color)]  p-8 shadow-md w-full lg:w-[48%] hovbox" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1000">
              <div className="flex flex-col items-center gap-4">
                <div className="flex items-center justify-center h-[74px] w-[74px] border-4 border-[var(--border-color)] p-4 bg-[var(--primary-bg)]">
                  <FaHandshake className="text-4xl text-[var(--button-color)]" />
                </div>
                <h3 className="text-sizes2 text-[var(--button-color)]">Trust</h3>
              </div>
              <p className="text-sizes text-[var(text-color)] mt-4 text-center">
                Trust is the cornerstone of every successful real estate transaction.
              </p>
            </div>

            {/* Card 2 */}
            <div className="flex flex-col items-center bg-[var(--secondary-bg)] border border-[var(--border-color)]  p-8 shadow-md w-full lg:w-[48%] hovbox" data-aos="fade-left" data-aos-delay="300" data-aos-duration="1000">
              <div className="flex flex-col items-center gap-4">
                <div className="flex items-center justify-center h-[74px] w-[74px]  border-4 border-[var(--border-color)] p-4 bg-[var(--primary-bg)]">
                  <FaStar className="text-4xl text-[var(--button-color)]" />
                </div>
                <h3 className="text-sizes2 text-[var(--button-color)]">Excellence</h3>
              </div>
              <p className="text-sizes text-[var(text-color)] mt-4 text-center">
                We set the bar high for ourselves. From the properties we list it.
              </p>
            </div>
          </div>

          {/* Second Row of Cards */}
          <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between gap-y-7 lg:gap-x-7 w-full" data-aos="fade-right" data-aos-delay="700" data-aos-duration="1000">
            {/* Card 3 */}
            <div className="flex flex-col items-center bg-[var(--secondary-bg)] border border-[var(--border-color)]  p-8 shadow-md w-full lg:w-[48%] hovbox">
              <div className="flex flex-col items-center gap-4">
                <div className="flex items-center justify-center h-[74px] w-[74px] border-4 border-[var(--border-color)] p-4 bg-[var(--primary-bg)]">
                  <FaUserFriends className="text-4xl text-[var(--button-color)]" />
                </div>
                <h3 className="text-sizes2 text-[var(--button-color)]">Client-Centric</h3>
              </div>
              <p className="text-sizes text-[var(text-color)] mt-4 text-center">
                Your dreams and needs are at the center of our universe.
              </p>
            </div>

            {/* Card 4 */}
            <div className="flex flex-col items-center bg-[var(--secondary-bg)] border border-[var(--border-color)]  p-8 shadow-md w-full lg:w-[48%] hovbox" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">
              <div className="flex flex-col items-center gap-4">
                <div className="flex items-center justify-center h-[74px] w-[74px] border-4 border-[var(--border-color)] p-4 bg-[var(--primary-bg)]">
                  <FaHandHoldingHeart className="text-4xl text-[var(--button-color)]" />
                </div>
                <h3 className="text-sizes2 text-[var(--button-color)]">Our Commitment</h3>
              </div>
              <p className="text-sizes text-[var(text-color)] mt-4 text-center">
                We are dedicated to providing you with the highest level of service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
