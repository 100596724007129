import {React,useEffect} from 'react';
import { AiOutlineUpload } from 'react-icons/ai';
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles

const PropForm = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
    });
  }, []); 
  return (
    <div className="flex flex-col items-center justify-center px-12  py-6 bg-[var(--primary-bg)] border-[var(--border-color)] sm:py-6 sm:px-12 md:py-6 md:px-12  lg:py-6 lg:px-12 xl:py-6 2xl:py-3">
      {/* First Section */}
      <section className="w-full mx-auto border-[var(--border-color)] border-2  m-4 sm:m-16 px-6 sm:px-12 py-12">
        <h1 className="text-sizes2 text-[var(--button-color)]" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500" >
          Let's Make it Happen
        </h1>
        <h1 className="text-sizes text-[var(--primary-text)] pt-3 mr-4" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500" >
          Ready to take the first step toward your dream property? Fill out the form below, and our real estate wizards will work their magic to find your perfect match. Don't wait; let's embark on this exciting journey together.        </h1>
        <form className="py-8 sm:py-16">
          <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {/* Username Input */}
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="username">
                First Name
              </label>
              <input
                id="username"
                type="text"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              />
            </div>
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="username">
                Last Name
              </label>
              <input
                id="username"
                type="text"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              />
            </div>

            {/* Email Address Input */}
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="emailAddress">
                Email
              </label>
              <input
                id="emailAddress"
                type="email"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              />
            </div>
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="phone">
                Phone Number
              </label>
              <input
                id="phone"
                type="tel"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              />
            </div>

            {/* Select Input */}
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="select">
                Preferred Location              </label>
              <select
                id="select"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              >
                <option>Jammu & Kashmir</option>
                <option>Punjab</option>
              </select>
            </div>

            {/* Select Input */}
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="select">
                Property Type              </label>
              <select
                id="select"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              >
                <option>Apartment</option>
                <option>Villa</option>
                <option>Commnercial</option>
              </select>
            </div>
            {/* Select Input */}
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="select">
                No of Bathrooms               </label>
              <select
                id="select"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            {/* Select Input */}
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="select">
                No of Bedrooms
              </label>
              <select
                id="select"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            {/* Select Input */}
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="select">
                Budget
              </label>
              <select
                id="select"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              >
                <option>$100k - $500k</option>
                <option>$1M - $5M</option>
                <option>$10M - $50M</option>
              </select>
            </div>
            {/* Select Input */}
            <div>
              <label className="text-[var(--primary-text)] text-sizes" htmlFor="select">
                Preferred Contact Method
              </label>
              <select
                id="select"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              >
                <option>Phone Number</option>
                <option>Email</option>

              </select>
            </div>




            {/* Text Area Input */}
            <div>
              <label className="text-[var(--primary-text)] text-sizes " htmlFor="textarea">
                Message
              </label>
              <textarea
                id="textarea"
                className="text-sizes block w-full px-4 py-2 mt-2 text-[var(--primary-text)] bg-[var(--secondary-bg)]  border border-[var(--border-color)] focus:outline-none focus:ring"
              ></textarea>
            </div>
          </div>

          {/* Save Button */}
          <div className="flex justify-end mt-6">
            <button className="px-6 py-2 leading-5 text-[var(--primary-text)] btn1" data-aos="fade-left" data-aos-delay="200" data-aos-duration="1500" >
              Send your message
            </button>
          </div>
        </form>
      </section>
    </div>

  );
};

export default PropForm;
