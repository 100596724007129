import React from 'react'
import HomeInfo from './HomeInfo'
import Featured from './Featured'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Testimonial from './Testimonial'
import Tabs from './Tabs'
import Stats from './Stats'
import TeamSection from './TeamSection'

const Home = () => {
  return (
    <div>
      <Navbar />
      <HomeInfo />
      <Tabs />
      <Stats />
      <Featured />
      <TeamSection />

      <Testimonial />
      <Footer />

    </div>
  )
}

export default Home
