// BlogList.js
import React from 'react';
import { blogs } from './Blog';
import BlogCard from './BlogCard';
import Navbar from '../../Navbar';
import Footer from '../../Footer';

const BlogList = () => {
  return (

    <>

      <Navbar />

      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {blogs.map((blog, index) => (
            <BlogCard key={index} blog={blog} />
          ))}
        </div>
      </div>
      <Footer />

    </>

  );
};

export default BlogList;
