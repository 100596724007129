import React from 'react'
import Top from '../Properties/Top'

import Navbar from '../Navbar'
import Footer from '../Footer'
import Prop from '../Properties/Prop'
import PropForm from '../Properties/PropForm'

const Properties = () => {
  return (
    <div>
      <Navbar />
      <Top />
      <Prop />
      <PropForm />
      <Footer />

    </div>
  )
}

export default Properties
