import { React, useEffect } from 'react'
import { FaTelegramPlane } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles
import { FaInstagram, FaYoutube, FaLinkedin, FaFacebook, FaTwitter } from 'react-icons/fa'; // Importing social media icons
import { AiFillPhone, AiOutlineMail } from 'react-icons/ai'; // Importing phone and email icons
import { FaMapMarkerAlt } from 'react-icons/fa'; // Importing location icon
import { FaAngleRight } from "react-icons/fa"; // Importing the arrow icon








const Footer = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
    });
  }, []);
  return (
    <>
      <div className=' bg-[var(--bg2)]  text-[var(--text2)] px-12 py-12 sm:flex-row  sm:py-8 sm:px-12 md:py-12 md:px-12  lg:py16 lg:px-12 xl:py-20 2xl:py-24 border-t border-[var(--borderc)]'>

        <div className='flex flex-col   border-[var(--button-color)] '>
          {/* Title */}
          <div className='text-sizes3  text-[var(--gold)] mt-4' data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500">
            Start Your Real Estate Journey Today
          </div>

          {/* Content + Button */}
          <div className='flex flex-col sm:flex-row'>
            {/* Text Section */}
            <div className='w-full sm:w-2/3 text-sizes py-4  flex-grow ' data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
              Your dream property is just a click away. Whether you're looking for a new home, a strategic investment, or expert real estate advice, RayUpRealEstate is here to assist you every step of the way.
            </div>

            {/* Button Section */}
            <div className='flex w-full sm:w-1/3 justify-start sm:justify-end items-center mt-4 sm:mt-0'>
              <Link to='/properties'>              <button className='bt2' data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">Explore Properties</button>
              </Link>
            </div>
          </div>
        </div>



      </div>

      {/* main footer */}


      <div className='bg-[var(--bg2)] text-[var(--text2)] px-12 py-6 text-sizes border-[var(--borderc)] border-t sm:py-8 sm:px-12 md:py-12 md:px-12  lg:py16 lg:px-12 xl:py-20 2xl:py-24'>
        <div className='grid grid-cols-2 sm:grid-cols-2 md:flex md:flex-wrap md:justify-between gap-2 w-full'>

          {/* Column 1 */}
          <ul className='w-full md:w-1/6 mb-4 md:mb-0 space-y-2'>
            <li className='text-[var(--gold)] font-bold uppercase ' data-aos="fade-down" data-aos-delay="100" data-aos-duration="1000">RAYUPREALESTATE</li>
            <p>             A keen eye on the market, a passion to excel, and a wide network of industry professionals. J.O. Group works to deliver the best possible experience with Real Estate.
            </p>
          </ul>

          {/* Column 2 */}

          <ul className='w-full md:w-1/6 mb-4 md:mb-0 space-y-2'>
            <li className='text-[var(--gold)] font-semibold uppercase' data-aos="fade-down" data-aos-delay="100" data-aos-duration="1000">
              Quick Links
            </li>

            {/* List Items with Arrow Icons */}
            <li className='flex items-center space-x-2' data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
              <FaAngleRight className="text-[var(--gold)]" /> {/* Arrow Icon */}
              <span>Listings in Alberta</span>
            </li>

            <li className='flex items-center space-x-2' data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
              <FaAngleRight className="text-[var(--gold)]" /> {/* Arrow Icon */}
              <span>About Us</span>
            </li>

            <li className='flex items-center space-x-2' data-aos="fade-up" data-aos-delay="400" data-aos-duration="1500">
              <FaAngleRight className="text-[var(--gold)]" /> {/* Arrow Icon */}
              <span>Looking to Sell</span>
            </li>

            <li className='flex items-center space-x-2' data-aos="fade-up" data-aos-delay="400" data-aos-duration="1500">
              <FaAngleRight className="text-[var(--gold)]" /> {/* Arrow Icon */}
              <span>Land for Sale</span>
            </li>

            <li className='flex items-center space-x-2' data-aos="fade-up" data-aos-delay="400" data-aos-duration="1500">
              <FaAngleRight className="text-[var(--gold)]" /> {/* Arrow Icon */}
              <span>Commercials</span>
            </li>

            <li className='flex items-center space-x-2' data-aos="fade-up" data-aos-delay="400" data-aos-duration="1500">
              <FaAngleRight className="text-[var(--gold)]" /> {/* Arrow Icon */}
              <span>Looking to Invest</span>
            </li>
          </ul>




          {/* Column 4 */}
          <ul className='w-full md:w-1/6 mb-4 md:mb-0 space-y-2'>
            <li className='text-[var(--gold)] font-semibold uppercase' data-aos="fade-down" data-aos-delay="100" data-aos-duration="1000">
              Contact Us
            </li>

            {/* Phone Icon and Number */}
            <li className='flex items-center' data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
              <AiFillPhone className='mr-2 text-2xl text-[var(--gold)]' /> {/* Phone icon */}
              <span>+1 234 567 890</span> {/* Replace with actual phone number */}
            </li>

            {/* Email Icon and Email */}
            <li className='flex items-center ' data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
              <AiOutlineMail className='mr-2 text-[var(--gold)] text-2xl' /> {/* Email icon */}
              <span>info@example.com</span> {/* Replace with actual email */}
            </li>

            {/* Address Icon and Address */}
            <li className='flex items-center' data-aos="fade-up" data-aos-delay="400" data-aos-duration="1500">
              <FaMapMarkerAlt className='mr-2 text-2xl text-[var(--gold)]' /> {/* Address icon */}
              <span>123 Main Street, City, Country</span> {/* Replace with actual address */}
            </li>
          </ul>
          {/* Logo and Email Input */}
          <div className='flex flex-col w-full md:w-1/4 mb-4 md:mb-0  '>
            <div className='flex items-center mb-2 text-sizesn font-semibold text-[var(--gold)]' data-aos="fade-down" data-aos-delay="200" data-aos-duration="1000">
              <h1>Follow us on</h1>
            </div>

            <div className='flex space-x-4'> {/* Flex container for icons with spacing */}
              {/* Instagram Icon */}
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <FaInstagram className='text-[var(--icon-color)] hover:text-[var(--hover-icon-color)] text-2xl' />
              </a>

              {/* YouTube Icon */}
              <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                <FaYoutube className='text-[var(--icon-color)] hover:text-[var(--hover-icon-color)] text-2xl' />
              </a>

              {/* LinkedIn Icon */}
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <FaLinkedin className='text-[var(--icon-color)] hover:text-[var(--hover-icon-color)] text-2xl' />
              </a>

              {/* Facebook Icon */}
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <FaFacebook className='text-[var(--icon-color)] hover:text-[var(--hover-icon-color)] text-2xl' />
              </a>

              {/* Twitter Icon */}
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                <FaTwitter className='text-[var(--icon-color)] hover:text-[var(--hover-icon-color)] text-2xl' />
              </a>
            </div>
          </div>

        </div>
      </div>


      {/* icons div */}







    </>
  )
}

export default Footer
