import React from 'react'
import Navbar from '../../Navbar'
import Footer from '../../Footer'
import { FaHotel } from "react-icons/fa";
import { MdLiquor } from "react-icons/md";
import { MdLocalGasStation } from "react-icons/md";
import { FaStore } from "react-icons/fa6";





const Business = () => {
  return (

    <>
      <Navbar />


      <div className="flex flex-wrap md:flex-nowrap justify-center bg-[var(--bg1)] px-12 pt-24 pb-24 font-semibold relative">
        <div
          className="bg-[var(--bg1)] border-2 py-8 px-8 md:px-4 border-[var(--borderc)] flex items-center justify-center flex-col m-2 w-[calc(50%-1rem)] md:w-1/4 hovbox"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="500"
        >
          <FaHotel className="text-sizes3 text-[var(--gold)] mb-4" />
          <p className="text-[var(--primary-text)] text-sizes">Restaurants</p>
        </div>

        <div
          className="bg-[var(--bg1)] border-2 py-8 px-8 md:px-4 border-[var(--borderc)] flex items-center justify-center flex-col m-2 w-[calc(50%-1rem)] md:w-1/4 hovbox"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="500"
        >
          <MdLiquor className="text-sizes3 text-[var(--gold)] mb-4" />
          <p className="text-[var(--primary-text)] text-sizes">Liquor Store</p>
        </div>



        <div
          className="bg-[var(--bg1)] border-2 py-8 px-8 md:px-4 border-[var(--borderc)] flex items-center justify-center flex-col m-2 w-[calc(50%-1rem)] md:w-1/4 hovbox"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-duration="500"
        >
          <MdLocalGasStation className="text-sizes3 text-[var(--gold)] mb-4" />
          <p className="text-[var(--primary-text)] text-sizes">Gas Station</p>
        </div>

        <div
          className="bg-[var(--bg1)] border-2 py-8 px-8 md:px-4 border-[var(--borderc)] flex items-center justify-center flex-col m-2 w-[calc(50%-1rem)] md:w-1/4 hovbox"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-duration="500"
        >
          <FaStore className="text-sizes3 text-[var(--gold)] mb-4" />
          <p className="text-[var(--primary-text)] text-sizes">Convinience Sotre</p>
        </div>
      </div>


      <Footer />


    </>

  )
}

export default Business